@import "./data/styles.css";

.footer {
	display: flex;
	padding-top: 25px;
	margin-top: 50px;
	padding-bottom: 25px;
	align-items: center;
	width:100%;
	justify-content: space-between;	
}

.footer-links,
.footer-credits {
	flex-basis: 30%;
}

.footer-links {
	/* margin-left: -40px; */
	width:100%;
	/* display: flex; */
	flex-basis: 60%;
	justify-content: space-between;
	align-items: center; 
}

.footer-nav-link-list {
	display: flex;
	width:100%;
	list-style: none;
	justify-content: space-between;
	align-items: center;
}

.footer-nav-link-item {
	font-weight: bold;
	font-size: 80%;
}

.footer-nav-link-list a {
	text-decoration: none;
	color: var(--secondary-color);
}

.footer-nav-link-list a:hover {
	color: var(--link-color);
}

.footer-credits-text {
	justify-content: flex-end;
	color: var(--tertiary-color);
	font-size: 14px;
	text-align: right;
}

@media (max-width: 600px) {
	.footer {
		flex-direction: column;
		height: 85px;
	}

	.footer-links {
		display: flex;
		width: 100%;
		justify-content: center;
	}

	.footer-nav-link-item {
		font-weight: bold;
		font-size: 60%;
	}

	.footer-nav-link-list {
		width: 100%;
	}
}